import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store, { key } from './store'
import { createI18n } from 'vue-i18n'
import messages from '@/messages'

const i18n = createI18n({
  locale: 'en',
  messages,
})

// @ts-ignore
const app = createApp(App, i18n)
  .use(store, key)
  .use(router)
  .use(i18n)

app.mount('#app')
