
import { computed, defineComponent, PropType, reactive } from 'vue'
import { MultipleChoiceAnswer } from '@@/models/Answer'
import Question, { MultipleChoiceItemLabel } from '@@/models/Question'
import emojiRegex from 'emoji-regex/RGI_Emoji'

export default defineComponent({
  name: 'MultipleChoiceInput',
  props: {
    question: { type: Object as PropType<Question>, required: true },
    modelValue: Map as PropType<MultipleChoiceAnswer>,
  },
  setup (props, { emit }) {
    const { question } = props
    const name = computed(() => question.name)
    const required = computed(() => question.required)
    const choices = computed(() => question.choices?.map(choice => ({
      ...choice,
      single: choice.label.toString().length === 1
    })))
    const allowMultiple = computed(() => question.allowMultiple)

    const listStyle = computed(() => question.display.listStyle)
    const listNumberingStyle = computed(() => question.display.listNumberingStyle)

    const update = (value: MultipleChoiceAnswer) => {
      const valid = [...answers.values()].some(value => value)
      emit('validated', valid)

      emit('update:modelValue', value)
    }
    const individualInputType = computed(() => allowMultiple.value ? 'checkbox' : 'radio')

    const answers = reactive(<MultipleChoiceAnswer>new Map())

    // initialize all answers to false
    choices.value && Object.values(choices.value).forEach(choice => {
      answers.set(choice.label, false)
    })
    update(answers)

    const setAnswerForLabel = (label: MultipleChoiceItemLabel, checked: boolean) => {
      // if only single allowed, reset map
      if (!allowMultiple.value) {
        [...answers.keys()].forEach((choice) => {
          answers.set(choice, false)
        })
      }

      answers.set(label, checked)
      update(answers)
    }

    const onlyEmoji = (string: MultipleChoiceItemLabel) => string.toString().replace(emojiRegex(), '').length === 0
    const emojiCount = (string: MultipleChoiceItemLabel) => string.toString().match(emojiRegex())?.length || 0
    const useBigChars = (string: MultipleChoiceItemLabel) => string.toString().length === 1 || (onlyEmoji(string.toString()) && emojiCount(string.toString()) < 4)

    return {
      name,
      required,
      listStyle,
      listNumberingStyle,

      useBigChars,
      onlyEmoji,

      update,
      individualInputType,
      answers,
      setAnswerForLabel
    }
  }
})
