
import { computed, defineComponent, onBeforeUpdate, onMounted } from 'vue'
import Question, { decodeQuestion } from '@@/models/Question'
import SingleQuestion from '@/components/SingleQuestion.vue'
import useGoto from '@/composables/useGoto'
import useForm from '@/composables/useForm'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { MutationTypes, useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Questionnaire',
  components: { AppFooter, AppHeader, SingleQuestion },
  props: {
    slug: { type: String, default: '' },
    lang: { type: String, default: 'en' },
  },
  setup (props, {}) {
    const questionnaire = require(`@@/questionnaires/${props.slug}-${props.lang}.yml`)
    const { title, headline, subheadline, name } = questionnaire
    const questions: Question[] = questionnaire.questions.map(decodeQuestion)

    const store = useStore()
    const questionNames = computed(() => store.state.questionNames)
    store.commit(MutationTypes.SET_QUESTION_NAMES, questions.map(question => question.name))

    const answers = computed(() => store.state.answers)
    const validCount = computed(() => [...answers.value.values()].filter(answer => answer.valid).length)
    const requiredQuestions = questions.filter(question => question.required)
    const requiredCount = requiredQuestions.length
    const requiredAndValid = computed(() =>
      requiredQuestions
        .filter(question => answers.value.get(question.name)?.valid)
    )
    const requiredAndValidCount = computed(() => requiredAndValid.value.length)
    const canSend = computed(() => requiredAndValidCount.value === requiredCount && !pending.value)

    store.commit(MutationTypes.SET_LANG, props.lang)
    onMounted(() => {
      document.title = title
      document.documentElement.lang = props.lang
    })

    const { t } = useI18n()
    const thanksString = t('response.thanks', {}, { locale: props.lang })
    const errorString = t('response.error', {}, { locale: props.lang })
    const resetString = t('response.reset', {}, { locale: props.lang })

    const { resetElements } = useGoto
    onBeforeUpdate(() => {
      resetElements()
    })

    const { submit: submitForm, pending, success, error, sent, reset } = useForm
    const submit = () => submitForm(answers.value, questionNames.value, name)

    return {
      questions,
      ...useGoto,

      headline,
      subheadline,
      name,

      answers,
      requiredQuestions,
      requiredCount,
      requiredAndValidCount: requiredAndValidCount,
      canSend,
      validCount: validCount,

      submit,
      pending,
      success,
      error,
      sent,
      reset,

      thanksString,
      errorString,
      resetString
    }
  },
})
