<template>
  <div class="multiple-choice-input" :class="[listNumberingStyle, listStyle]">
    <fieldset class="choices">
      <label v-for="[label, checked] in answers"
             class="choice"
             :class="{ checked }"
      >
        <input class="visually-hidden"
               :name="name"
               :type="individualInputType"
               @input="setAnswerForLabel(label, $event.target.checked)"
        />
        <span class="label-span"
              :class="{ 'big-chars': useBigChars(label), 'only-emoji': onlyEmoji(label) }"
              v-text="label"
        />
      </label>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive } from 'vue'
import { MultipleChoiceAnswer } from '@@/models/Answer'
import Question, { MultipleChoiceItemLabel } from '@@/models/Question'
import emojiRegex from 'emoji-regex/RGI_Emoji'

export default defineComponent({
  name: 'MultipleChoiceInput',
  props: {
    question: { type: Object as PropType<Question>, required: true },
    modelValue: Map as PropType<MultipleChoiceAnswer>,
  },
  setup (props, { emit }) {
    const { question } = props
    const name = computed(() => question.name)
    const required = computed(() => question.required)
    const choices = computed(() => question.choices?.map(choice => ({
      ...choice,
      single: choice.label.toString().length === 1
    })))
    const allowMultiple = computed(() => question.allowMultiple)

    const listStyle = computed(() => question.display.listStyle)
    const listNumberingStyle = computed(() => question.display.listNumberingStyle)

    const update = (value: MultipleChoiceAnswer) => {
      const valid = [...answers.values()].some(value => value)
      emit('validated', valid)

      emit('update:modelValue', value)
    }
    const individualInputType = computed(() => allowMultiple.value ? 'checkbox' : 'radio')

    const answers = reactive(<MultipleChoiceAnswer>new Map())

    // initialize all answers to false
    choices.value && Object.values(choices.value).forEach(choice => {
      answers.set(choice.label, false)
    })
    update(answers)

    const setAnswerForLabel = (label: MultipleChoiceItemLabel, checked: boolean) => {
      // if only single allowed, reset map
      if (!allowMultiple.value) {
        [...answers.keys()].forEach((choice) => {
          answers.set(choice, false)
        })
      }

      answers.set(label, checked)
      update(answers)
    }

    const onlyEmoji = (string: MultipleChoiceItemLabel) => string.toString().replace(emojiRegex(), '').length === 0
    const emojiCount = (string: MultipleChoiceItemLabel) => string.toString().match(emojiRegex())?.length || 0
    const useBigChars = (string: MultipleChoiceItemLabel) => string.toString().length === 1 || (onlyEmoji(string.toString()) && emojiCount(string.toString()) < 4)

    return {
      name,
      required,
      listStyle,
      listNumberingStyle,

      useBigChars,
      onlyEmoji,

      update,
      individualInputType,
      answers,
      setAnswerForLabel
    }
  }
})
</script>

<style scoped lang="sass">
$gap: .25em

.multiple-choice-input

.choices
  counter-reset: i
  gap: $gap

  .list &
    display: flex
    flex-direction: column

  .tiles &
    display: grid
    grid-template-columns: repeat(4, minmax(25%, 1fr))
    grid-auto-rows: 1fr
    margin-top: .25em

.choice
  border: solid 1px transparent
  counter-increment: i
  display: flex
  align-items: baseline
  padding: .25em

  &.checked
    border-color: $blue

  &:before
    display: block
    min-width: 1em
    font-size: .5em
    opacity: .5
    margin-inline-end: .25em

  .lettered &
    &:before
      content: counter(i, upper-alpha)

  .numbered &
    &:before
      content: counter(i)

  .list &
    margin-inline-start: -.25em

  .tiles &
    display: grid
    place-items: center
    grid-template-rows: 1fr auto
    text-align: center

    .label-span
      font-size: 1.5em
      margin-bottom: .15em

      &.big-chars
        font-size: 2.5em

      &.only-emoji
        letter-spacing: .25em

    &:before
      order: 1
      margin: 2em

</style>