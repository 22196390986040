import { slugify } from '../utils/js/functions/stringManipulation'

export enum QuestionType {
  string = 'string',
  text = 'text',
  multipleChoice = 'multipleChoice',
  stringList = 'stringList',
}

export enum QuestionInputComponentName {
  string = 'StringInput',
  text = 'TextInput',
  stringList = 'StringListInput',
  multipleChoice = 'MultipleChoiceInput',
}

export const questionInputComponentNameForType = (type: QuestionType) => QuestionInputComponentName[type]

export type MultipleChoiceItemLabel = string | number // todo: icon type, image type

export interface QuestionChoice {
  label: MultipleChoiceItemLabel
}

export enum OptionsListNumberingStyle {
  lettered = 'lettered',
  numbered = 'numbered',
  none = 'none',
}
export enum OptionsListStyle {
  list = 'list',
  tiles = 'tiles'
}

interface QuestionDisplayOptions {
  listStyle: OptionsListStyle
  listNumberingStyle: OptionsListNumberingStyle
}

export default interface Question {
  type: QuestionType
  name: string
  label: string
  description?: string | string[]
  required: boolean
  choices?: QuestionChoice[]
  allowMultiple: boolean
  display: QuestionDisplayOptions
}

const defaultType = QuestionType.string
const defaultAllowMultiple = false
const defaultDisplayOptions: QuestionDisplayOptions = {
  listNumberingStyle: OptionsListNumberingStyle.lettered,
  listStyle: OptionsListStyle.list
}

export const decodeQuestion = ({
                                 description,
                                 label,
                                 name,
                                 required,
                                 type,
                                 choices,
                                 allowMultiple,
                                 display
                               }: any): Question => ({
  type: type ?? defaultType,
  label,
  name: name ?? slugify(label),
  description,
  required: !!required,
  choices,
  allowMultiple: allowMultiple ?? defaultAllowMultiple,
  display: { ...defaultDisplayOptions, ...display }, // todo: real deep merge
})