
import { defineComponent } from '@vue/runtime-core'
import { computed, PropType, ref } from 'vue'
import { ListAnswer } from '@@/models/Answer'
import Question from '@@/models/Question'

export default defineComponent({
  name: 'StringListInput',
  props: {
    question: { type: Object as PropType<Question>, required: true },
    modelValue: Object as PropType<ListAnswer>,
  },
  setup (props, { emit }) {
    const { question } = props
    const name = computed(() => question!.name)
    const required = computed(() => question!.required)
    const display = computed(() => question!.display)

    const setItem = ($event, index: number | string = (props.modelValue as ListAnswer).length) => {
      const currentValue = props.modelValue as ListAnswer
      currentValue[index] = $event.target.value
      update(currentValue)
    }

    const newItem = ref('')
    const newItemInputElement = ref()

    const update = (value: ListAnswer) => {
      emit('validated', !!value.length)
      emit('update:modelValue', value)
    }

    // init to empty array
    update([])

    const updateNewItem = event => {
      newItem.value = event.target.value
    }

    const addItem = $event => {
      if ($event.target.value) {
        setItem($event)
        newItem.value = ''
      } else next()
    }

    const removeItem = index => {
      const currentValue = props.modelValue as ListAnswer
      currentValue.splice(index, 1)
      update(currentValue)
    }

    const removeIfEmpty = i => {
      if (!(props.modelValue as ListAnswer)[i]) {
        removeItem(i)
        newItemInputElement.value.focus()
      }
    }

    const up = (event, index: number | string = (props.modelValue as ListAnswer).length) => {
      if (index > 0)
        event.target.closest('.input').previousElementSibling?.querySelector('input')?.focus()
    }

    const down = (event, index: number | string = (props.modelValue as ListAnswer).length) => {
      if (index < (props.modelValue as ListAnswer).length)
        event.target.closest('.input').nextElementSibling?.querySelector('input')?.focus()
    }

    const next = () => emit('next')
    const prev = () => emit('prev')

    return {
      name,
      required,
      display,

      update,
      next,
      prev,

      setItem,
      addItem,
      newItem,
      newItemInputElement,
      updateNewItem,
      removeIfEmpty,

      up,
      down
    }
  }
})
