<template>
  <footer class="app-footer">
    <div class="content">
      <div class="logo-wide">
        <span>Studio Praktik</span>
      </div>
      <div class="action">
        <p class="counter" id="counter">
          <span class="of" v-text="filledString"/>
          <span class="current" v-text="filled"/>
          <span class="of" v-text="ofString"/>
          <span class="all" v-text="count"/>
        </p>
        <button class="button submit" id="submit-button" :disabled="!canSend" type="submit" v-text="sendString"/>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppFooter',
  props: {
    count: { type: Number, required: true },
    filled: { type: Number, default: 0 },
    canSend: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore()
    const lang = computed(() => store.state.lang)
    const { t } = useI18n()
    const filledString = computed(() => t('footer.filled', {}, { locale: lang.value }))
    const ofString = computed(() => t('footer.of', {}, { locale: lang.value }))
    const sendString = computed(() => t('footer.send', {}, { locale: lang.value }))

    return  {
      filledString,
      ofString,
      sendString
    }
  }
}
</script>

<style scoped lang="sass">
.app-footer
  position: fixed
  bottom: 0
  left: 0
  right: 0
  height: 80px
  background-color: var(--window-bg)
  border-top: solid 1px var(--bg-dark)
  z-index: 1

  .content
    padding: 0 5vw
    +full-size-absolute
    +flex-space
    max-width: none

  .action
    display: flex
    align-items: center

  .counter
    margin-left: 12px
    font-size: .8em
    color: vaR(--body-main)
    flex-shrink: 0

    +en
      margin-right: 12px
      margin-left: 0

    &.full
      color: var(--accent)

  span.of
    display: inline-block
    margin: 0 .3em

  .logo-wide
    +small
      font-size: .65em

</style>