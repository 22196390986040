<template>
  <router-view/>
</template>

<style lang="sass">
@import assets/style/style
</style>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  mounted () {
    setTimeout(() => document.body.classList.add('snap'), 50)
  }
})
</script>