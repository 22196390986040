
import Question, { questionInputComponentNameForType } from '@@/models/Question'
import { AnswerValue } from '@@/models/Answer'
import { computed, defineComponent, PropType, ref, watchEffect } from 'vue'
import StringInput from '@/components/inputs/StringInput.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import StringListInput from '@/components/inputs/StringListInput.vue'
import MultipleChoiceInput from '@/components/inputs/MultipleChoiceInput.vue'
import { MutationTypes, useStore } from '@/store'

export default defineComponent({
  name: 'SingleQuestion',
  components: { StringInput, TextInput, StringListInput, MultipleChoiceInput },
  props: {
    question: { type: Object as PropType<Question>, required: true },
  },
  setup (props, { emit }) {
    const { commit, state: { lang } } = useStore()
    const { required, type, name } = props.question as Question

    const value = ref<AnswerValue>(null)

    const commitAnswer = () => {
      commit(MutationTypes.SET_ANSWER, { name, type, value: value.value, valid })
    }

    let valid: boolean
    const setValid = (_valid: boolean) => {
      valid = _valid
      commitAnswer()
    }

    watchEffect(() => {
      commitAnswer()
    })

    const inputComponent = computed(() => questionInputComponentNameForType(type))
    const next = () => emit('next')
    const prev = () => emit('prev')

    const id = `field-${escape(name)}`

    const { question: labelStrings } = require('@@/ui-strings.yml')
    const nextLabelString = labelStrings.next[lang]
    const enterLabelString = labelStrings.enter[lang]

    return { value, setValid, inputComponent, next, prev, required, id, nextLabelString, enterLabelString }
  },
})