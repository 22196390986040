/**
 * @see https://next.vuex.vuejs.org/guide/typescript-support.html
 * @see https://dev.to/3vilarthas/vuex-typescript-m4j
 * @see https://github.com/ErikCH/Vue3TypeScript/blob/vuex/src/store/index.ts
 * @desc hey man, I just work here
 */

import { InjectionKey } from 'vue'
import {
  ActionContext,
  ActionTree,
  CommitOptions,
  createLogger,
  createStore,
  DispatchOptions,
  GetterTree,
  MutationTree,
  Store as VuexStore,
  useStore as baseUseStore
} from 'vuex'
import Answer from '@@/models/Answer'

export type Answers = Map<string, Answer>


// state
export interface State {
  answers: Answers,
  questionNames: string[],
  lang: string
}

const state: State = {
  answers: new Map() as Answers,
  questionNames: [],
  lang: ''
}


// mutations
export enum MutationTypes {
  SET_ANSWER = 'SET_ANSWER',
  SET_QUESTION_NAMES = 'SET_QUESTION_NAMES',
  SET_LANG = 'SET_LANG'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_ANSWER] (state: S, answer: Answer): void,
  [MutationTypes.SET_QUESTION_NAMES] (state: S, names: string[]): void,
  [MutationTypes.SET_LANG] (state: S, lang: string): void,
}

const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ANSWER] (state, answer) {
    const { name } = answer
    state.answers.set(name, answer)
  },

  [MutationTypes.SET_QUESTION_NAMES] (state, names) {
    state.questionNames = names
  },

  [MutationTypes.SET_LANG] (state, lang) {
    state.lang = lang
  }
}


// actions
export enum ActionTypes {
  SET_ANSWER = 'SET_ANSWER'
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations> (
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
  [ActionTypes.SET_ANSWER] (
    { commit }: AugmentedActionContext,
    payload: never
  ): void
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.SET_ANSWER] ({ commit }, payload: Answer) {
    commit(MutationTypes.SET_ANSWER, payload)
  }
}


// getters
export type Getters = {
  answers (state: State): Answers
}

export const getters: GetterTree<State, State> & Getters = {
  answers: state => state.answers
}

export type TypedStore<S> = Omit<VuexStore<S>,
  'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]> (
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
} & {
  dispatch<K extends keyof Actions> (
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
}

export default createStore<State>({
  state,
  mutations,
  actions,
  getters,
  plugins: [createLogger()]
})

// define injection key
export const key: InjectionKey<TypedStore<State>> = Symbol()
// define your own `useStore` composition function
export const useStore = () => baseUseStore(key) as TypedStore<State>