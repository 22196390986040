import firebase from 'firebase/app'
import 'firebase/firestore'
import Answer, { encodeAnswer } from './models/Answer'

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDi_CpqHFHWC-FFn11iYvxpazZI8Uv49jA",
  authDomain: "forma-2150c.firebaseapp.com",
  databaseURL: "https://forma-2150c.firebaseio.com",
  projectId: "forma-2150c",
  storageBucket: "forma-2150c.appspot.com",
  messagingSenderId: "323096585709",
  appId: "1:323096585709:web:54a87a09e71b3a589873e4",
  measurementId: "G-KQKDZSV5TP"
})

export const db = firebaseApp.firestore()
export const submissionsCollection = db.collection('submissions')
export const addSubmission = (answers: Answer[], name: string) => submissionsCollection.add({
  name,
  answers: answers.map(encodeAnswer),
  timestamp: FieldValue.serverTimestamp()
})

const { Timestamp, GeoPoint, FieldValue } = firebase.firestore
export { Timestamp, GeoPoint }