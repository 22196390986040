import { ref, Ref } from 'vue';

const itemRefs: Ref<any[]> = ref([])

const goto = (index: number) => {
  const nextElementObject = itemRefs.value[index];
  if (nextElementObject) {
    const $nextElement: HTMLElement = nextElementObject.$el
    $nextElement.scrollIntoView()

    setTimeout(() => {
      let input = $nextElement.querySelector('input') || $nextElement.querySelector('textarea')
      input?.focus()
    }, 500)
  }
}

const next = (currentIndex: number) => {
  goto(currentIndex + 1)
}

const prev = (currentIndex: number) => {
  goto(currentIndex - 1)
}

const resetElements = () => { itemRefs.value = [] }

const setItemRef = el => { itemRefs.value.push(el) }

export default {
  itemRefs,
  goto,
  prev,
  next,
  resetElements,
  setItemRef
}