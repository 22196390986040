import { computed, Ref, ref } from 'vue'
import { Answers } from '@/store'
import { addSubmission } from '@@/firebase'

const pending = ref(false)
const success = ref(false)
const error: Ref<null | string> = ref(null)

const sent = computed(() => success.value || error.value)

const submit = (answers: Answers, questionNames: string[], name) => {
  valuesToStart()
  pending.value = true

  // extract answer values
  const _answers = Array.from(answers.values())

  // sort by question order
  _answers.sort((a, b) =>
    questionNames
      .findIndex(name => name === a.name)
    - questionNames
      .findIndex(name => name === b.name))

  addSubmission(_answers, name)
    .then(({ id }) => {
      success.value = true
      sendNotification(id)
        .catch(error => {
          console.warn({ error })
        })
    })
    .catch(err => {
      error.value = err
    })
    .finally(() => {
      pending.value = false
    })
}

const reset = () => {
  pending.value = false
  success.value = false
  error.value = null
}

const valuesToStart = () => {
  pending.value = true
  success.value = false
  error.value = null
}

const sendNotification = async (id): Promise<any> => {
  const response = await fetch(`/api/send-email?id=${id}`, {
    method: 'POST'
  })

  if (!response.ok)
    return Promise.reject(response.body)
}

export default {
  submit,
  pending,
  success,
  error,
  sent,
  reset
}