<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <div class="text-input"
       :data-value="modelValue"
  >
    <textarea :name="name"
              :required="required"
              :value="modelValue"
              @input="update($event.target.value)"
              placeholder="&nbsp;"
              v-bind="$attrs"
              @keypress.enter.exact.prevent="next"
              @keypress.enter.shift.exact.stop
              rows="1"
    />
  </div>
  <span class="hint" v-text="newLineString"/>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Prop } from 'vue'
import { TextAnswer } from '@@/models/Answer'
import { useStore } from '@/store'
import Question from '@@/models/Question'

export default defineComponent({
  name: 'TextInput',
  props: {
    question: { type: Object as PropType<Question>, required: true },
    modelValue: String as PropType<TextAnswer>,
  },
  setup (props, { emit }) {
    const { question } = props
    const name = computed(() => question!.name)
    const required = computed(() => question!.required)
    const display = computed(() => question!.display)

    const update = value => {
      emit('validated', !!value)
      emit('update:modelValue', value)
    }

    // init to empty string
    update('')

    const next = () =>
      // prevent if empty && required
      !(required.value && !props.modelValue) && emit('next')
    const prev = () => emit('prev')

    const { state: { lang } } = useStore()
    const { question: labelStrings } = require('@@/ui-strings.yml')
    const newLineString = labelStrings.newLine[lang]

    return {
      name,
      required,
      display,

      update,
      next,
      prev,

      newLineString,
    }
  }
})
</script>

<style scoped lang="sass">
.text-input
  display: grid

  &:after
    content: attr(data-value) ' '
    white-space: pre-wrap
    visibility: hidden
    font: inherit
    padding: .5em 0 .5em
    border: solid 1px transparent
    font-size: 1.5em

  &:after,
  textarea
    grid-area: 1/1/-1/-1

  textarea
    resize: none
    overflow: hidden
</style>