import { MultipleChoiceItemLabel, QuestionType } from './Question'

export default interface Answer {
  name: string
  type: QuestionType
  value: AnswerValue
  valid?: boolean
}

export type StringAnswer = string
export type TextAnswer = string
export type ListAnswer = string[]
export type MultipleChoiceAnswer = Map<MultipleChoiceItemLabel, boolean>

export type AnswerValue =
  | null
  | StringAnswer
  | TextAnswer
  | ListAnswer
  | MultipleChoiceAnswer

export const encodeAnswer = (answer: Answer) => {
  let value: AnswerValue | Object = answer.value

  switch (answer.type) {
    case QuestionType.multipleChoice:
      const entries = (answer.value as MultipleChoiceAnswer).entries()
      // @ts-ignore why ts, why???
      value = Object.fromEntries(entries)
      break
  }

  return {
    ...answer,
    value
  }
}