
import { computed, defineComponent, PropType, Prop } from 'vue'
import { TextAnswer } from '@@/models/Answer'
import { useStore } from '@/store'
import Question from '@@/models/Question'

export default defineComponent({
  name: 'TextInput',
  props: {
    question: { type: Object as PropType<Question>, required: true },
    modelValue: String as PropType<TextAnswer>,
  },
  setup (props, { emit }) {
    const { question } = props
    const name = computed(() => question!.name)
    const required = computed(() => question!.required)
    const display = computed(() => question!.display)

    const update = value => {
      emit('validated', !!value)
      emit('update:modelValue', value)
    }

    // init to empty string
    update('')

    const next = () =>
      // prevent if empty && required
      !(required.value && !props.modelValue) && emit('next')
    const prev = () => emit('prev')

    const { state: { lang } } = useStore()
    const { question: labelStrings } = require('@@/ui-strings.yml')
    const newLineString = labelStrings.newLine[lang]

    return {
      name,
      required,
      display,

      update,
      next,
      prev,

      newLineString,
    }
  }
})
