<template>
  <div class="app-header">
    <div class="logo">
      <img src="/img/logo.svg" alt="P+">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped lang="sass">
//.app-header

</style>