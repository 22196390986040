
import { computed, defineComponent, PropType } from 'vue'
import { StringAnswer } from '@@/models/Answer'
import Question from '@@/models/Question'

export default defineComponent({
  name: 'StringInput',
  props: {
    question: { type: Object as PropType<Question>, required: true },
    modelValue: String as PropType<StringAnswer>,
  },
  setup (props, { emit }) {
    const { question } = props
    const name = computed(() => question.name)
    const required = computed(() => question.required)
    const display = computed(() => question.display)

    const update = value => {
      emit('validated', !!value)
      emit('update:modelValue', value)
    }

    // init to empty string
    update('')

    const next = () =>
      // prevent if empty && required
      !(required.value && !props.modelValue) && emit('next')
    const prev = () => emit('prev')

    return {
      name,
      required,
      display,

      update,
      next,
      prev,
    }
  }
})
