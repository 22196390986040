const messages = {
  en: {
    footer: {
      filled: 'Filled',
      of: 'of',
      send: 'Send',
    },
    response: {
      thanks: 'Thanks!',
      error: 'Error sending :/',
      reset: `Let's try again`,
    },
  },
  he: {
    footer: {
      filled: 'מילאת',
      of: 'מתוך',
      send: 'שליחה',
    },
    response: {
      thanks: 'תודה רבה!',
      error: 'תקלה בשליחה :/',
      reset: 'בואו ננסה שוב',
    },
  }
}

export default messages