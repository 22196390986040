
import { computed } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AppFooter',
  props: {
    count: { type: Number, required: true },
    filled: { type: Number, default: 0 },
    canSend: { type: Boolean, default: false },
  },
  setup() {
    const store = useStore()
    const lang = computed(() => store.state.lang)
    const { t } = useI18n()
    const filledString = computed(() => t('footer.filled', {}, { locale: lang.value }))
    const ofString = computed(() => t('footer.of', {}, { locale: lang.value }))
    const sendString = computed(() => t('footer.send', {}, { locale: lang.value }))

    return  {
      filledString,
      ofString,
      sendString
    }
  }
}
